import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/reset.css';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/vue'

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'f1d240fd81cfcae2b3d71cbef4c94b54'

// 2. Set chains
const merlin = {
    chainId: 4200,
    name: 'Merlin',
    currency: 'BTC',
    explorerUrl: 'https://scan.merlinchain.io',
    rpcUrl: 'https://rpc.merlinchain.io'
}

// 3. Create your application's metadata object
const metadata = {
    name: 'MerlinGrail',
    description: 'The  Grail Poker War has begun',
    url: 'https://merlingrail.xyz', // url must match your domain & subdomain
    icons: ['https://unicorn-cdn.b-cdn.net/97879e62-b1f9-4935-98da-58e4155aaf3b/字母icon-400x400-拷贝.png']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
})

// 5. Create a Web3Modal instance
createWeb3Modal({
    ethersConfig,
    chains: [merlin],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    defaultChain: merlin,
    chainImages: {
        4200: 'https://scan.merlinchain.io/svgs/logo/merlin.png'
    },
    allWallets: 'HIDE',
    featuredWalletIds: [
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709'
    ],
})

const app = createApp(App);

app.use(Antd).mount('#app');